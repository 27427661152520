/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Acme', cursive; /* or your preferred font */
  background-color: #d3d3d3; /* Light grey background */
  color: #333; /* Standard text color */
}


.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

header {
  text-align: center;
  padding: 10px 0;
  margin-bottom: 20px; /* Space below header */
}

header h1 {
  font-family: 'Bangers';
  color: #ff4444; /* Bold text color */
  text-transform: uppercase;
  font-size: 3em;
  margin: 0;
  line-height: 1;
  display: inline-block; /* Makes the background only around the text */
  background-color: #f0f0f0; /* Background color of main content for blending */
  padding: 10px; /* Padding around text */
  border: 2px solid #000; /* Border around text */
  box-shadow: 5px 5px 0px rgba(0,0,0,0.7); /* Shadow for depth */
}

/* Main Content Styles */

main input[type="text"] {
  width: 500px; /* Adjust as needed */
  max-width: 100%; /* Ensures responsiveness */
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #333;
  border-radius: 5px;
}

main {
  padding: 20px;
}

main h3 {
  font-family: 'Bangers', cursive;
  background-color: rgb(159, 25, 29); /* Example background color */
  color: white; /* Example text color */
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6); /* Bigger box shadow */
}

main select {
  font-family: 'Acme', cursive;
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 2px solid #333;
  border-radius: 5px;
  background-color: white;
  color: black;
}

main th img {
  height: 50px; /* Smaller height */
  width: auto; /* Maintain aspect ratio */
  /* Optional: Add more styling as needed */
}



/* Responsive Design */
@media (max-width: 600px) {
  main input[type="text"] {
      width: 90%; /* Smaller screens use percentage-based width */
  }
}


input[type="text"], select {
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 2px solid #333; /* Comic-style border */
  border-radius: 5px; /* Slightly rounded corners */
}

button {
  background-color: green; /* Bright red */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  font-family: 'Bangers', cursive;
  font-size: 20px;
  box-shadow: 3px 3px 0 black;
  transition: transform 0.1s ease-in-out;
  font-size: "16px";
}

button:hover {
  transform: scale(1.1);
}

/* Table */
table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Acme';
}

table, th, td {
  border: 1px solid black;
}

th, td {
  padding: 0.5rem;
  text-align: left;
}

/* Table Styles */
main table {
  width: 40%; /* Smaller width */
  margin: 20px auto; /* Center the table */
  border-collapse: collapse;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  background-color: white;
}

main th, main td {
  border: 1px solid #333;
  padding: 8px; /* Reduced padding */
  text-align: left;
  font-weight: 100; /* Makes text bold */
  color: black; /* Sets text color to black */
  text-align: center; /* Centers the text horizontally */
  vertical-align: middle;
}

main th {
  background-color: #ff4444;
  color: white;
  font-family: 'Bangers';
}

main td {
  font-size: 18px;
  font-weight: bold;
}

/* Responsive Table */
@media screen and (max-width: 600px) {
  main table {
      width: 100%; /* Full width on smaller screens */
      display: block;
      overflow-x: auto;
  }

  main th, main td {
      padding: 5px; /* Even smaller padding on small screens */
  }
}



/* Footer */
footer {
  background-color: #333;
  color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.spinner {
  display: block;
  margin: auto;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

nav ul {
  list-style: none;
  padding: 0;
}

nav li {
  display: inline-block; 
  margin-right: 20px;
  background-color: #f0f0f0; /* Light background for the box */
  padding: 10px;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3); /* Shadow effect */
}

nav a {
  text-decoration: none;
  color: #333;
  font-family: "Bangers"
  /* Add more styles as per your theme */
}

.nav-link {
  /* Existing styles for your nav link */
  display: inline-block; /* Allows transform to work properly */
  transition: transform 0.3s ease; /* Smooth transition for the transform */
}

.nav-link:hover {
  transform: scale(1.1); /* Increase the size of the element by 10% */
}


.highlight {
  background-color: yellow; /* Choose a color that stands out */
}

.table-container {
  display: flex;
  align-items: flex-start;
}

.refresh-button {
  background-color: transparent;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle; /* Aligns button with the text */
  font-size: 0.8em; /* Smaller size */
  padding: 3px 5px; /* Smaller padding */
  line-height: 1; /* Adjust line height to align better */
}

.refresh-button:hover {
  color: #45a049;
}

.clickable-header {
  cursor: pointer;
  color: white; /* Darker color for better visibility */
  display: inline-block; /* Ensure proper spacing and alignment */
  padding: 2px 5px; /* Optional padding for easier clicking */
}

.clickable-header:hover {
  color: #555; /* Slightly different color on hover for feedback */
}

.rating-header {
  white-space: nowrap; /* Ensures text and icon stay in the same line */
  padding-right: 20px; /* Extra padding on the right, adjust as needed */
}



/* Additional styles for table, if needed */
.charts-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows charts to wrap if the screen is too small */
  max-width: 100%; /* Ensures the container does not exceed the screen width */
  margin: auto;
  color: black;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.chart-container.full-width {
  max-width: 100%;
}

.player-table, .player-chart {
  flex: 1;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-container {
  /* Styles for the table container */
}

.chart-container {
  flex: 1; /* Each chart grows equally */
  max-width: 35%;
  max-height: 50%; /* Maximum width for each chart */
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #333; /* Stronger border */
  margin: 5px; /* Spacing between charts */
  background-color: #fff; /* Light background for the container */
  border: 1px solid #ddd; /* Subtle border for a defined edge */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 8px; /* Rounded corners for the shadow box */
}
/* Ensure the chart is responsive and fits its container */
.chart-container canvas {
  max-width: 100%;
  height: auto !important; /* Override Chart.js default height */
}

.player-chart-container canvas {
  max-width: 100%;
  height: auto !important; /* Override Chart.js default height */
}

.player-card {
  display: flex;
  flex: 1;
  flex-direction: row; /* Align children horizontally */
  justify-content: flex-start; /* Align children to the start */
  align-items: flex-start; /* Align items to the top */
  max-width: 35%;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  padding: 20px;
  background-color: #f2f2f2;;
  border-radius: 8px;
  margin-bottom: 20px;
  color: black;
  margin-left: auto;
  margin-right: auto;
}

.chart-and-table-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}


.profile-info {
  display: flex;
  flex-direction: column; /* Stack the image and text vertically */
  align-items: flex-start; /* Align content to the left */
  margin-right: 2px; /* Space between profile info and game info */
}
.profile-section {
  padding-right: 10px; /* Adjust the padding as needed */
  padding-top: 20px;
}

.ens-avatar {
  width: 200px; /* Adjust width as desired */
  height: auto; /* Adjusts height automatically to maintain aspect ratio */
  margin-bottom: 1px;
}

.name-wallet-info {
  text-align: center;
}

.name-wallet-info h3 {
  font-family: 'Bangers', cursive;
  background-color: rgb(159, 25, 29); /* Example background color */
  color: white; /* Example text color */
  padding: 5px 10px;
  text-align: center;
}

.game-info {
  display: flex;
  flex-direction: column;
  margin-left: 15px; /* Adjust the margin as needed */
  flex-grow: 1;
}

.game-info h4 {
  margin-bottom: 5px;
}

.game-info ul {
  list-style-type: none;
  padding: 0;
}

.game-info ul li {
  margin-bottom: 2px;
}

.game-mode-list {
  list-style: none;
  padding: 0;
}

.game-mode-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.game-mode-image {
  width: 75px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  margin-right: 20px;
}

.profile-and-chart-container {
  display: flex;
  align-items: flex-start; /* Align items to the start of the cross axis */
  gap: 5px;
  justify-content: space-between;
  margin: 20px; /* Adjust the gap as needed */
}

.player-chart-container {
  /* Existing styles for layout */
  flex: 1;
  padding: 10px; /* Adjust padding inside the container */
  margin: 10px; /* Space around the container */
  background-color: #fff; /* Light background for the container */
  border: 1px solid #ddd; /* Subtle border for a defined edge */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 10px; /* Rounded corners for the shadow box */
}



/* If using a library like Chart.js with React, ensure the chart itself fills its container */
.player-chart {
  height: 100%;
}

.player-card, .player-chart-container {
  margin-top: 0;
  padding-top: 0;
}

.ratings-info {
  background-color: #f9f9f990; /* Light background for the section */
  padding: 10px; /* Padding inside the section */
  margin-top: 20px; /* Margin at the top to separate from preceding content */
  border: 1px solid #ccc; /* Subtle border for definition */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 5px; /* Rounded corners */
  text-align: left; /* Align text to the left */
}

.ratings-info h4, .ratings-info p {
  font-size: 0.9rem; /* Smaller text size */
  margin: 5px 0; /* Adjusted spacing */
}

.shadow-box {
  background-color: #c94242; /* Light background for the section */
  padding: 15px; /* Padding inside the section */
  margin: 20px 0; /* Margin for spacing around the section */
  border: 1px solid #ccc; /* Subtle border for definition */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Center align the content for this example */
}

.credits-payouts-card {
  flex: 1; /* Each chart grows equally */
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 35%;
  max-height: 50%; /* Maximum width for each chart */
  color: black;
  padding: 5px;
  box-sizing: border-box;
  border: 2px solid #333; /* Stronger border */
  margin: 5px; /* Spacing between charts */
  background-color: #fff; /* Light background for the container */
  border: 1px solid #ddd; /* Subtle border for a defined edge */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 8px; /* Rounded corners for the shadow box */
}

.credits-payouts-card h3 {
  margin-bottom: 20px; /* Spacing between the title and the table */
}

.credits-payouts-card table {
  width: 100%; /* Make the table full-width of the card */
  border-collapse: collapse; /* Ensures there's no extra space between borders */
  border: none; /* Explicitly remove borders */
}

.credits-payouts-card table td {
  text-align: center; /* Center content horizontally */
  vertical-align: middle; /* Center content vertically */
}


.credits-payouts-card table td, .credits-payouts-card table th {
  border: none; /* Ensure no borders for table cells and headers */
  padding: 1px 0; /* Padding for table cells */
  text-align: left; /* Align text to the left */
}

.tabs {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-bottom: 5px;
}

.tabs li {
  background-color: red; /* Bright red */
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  font-family: 'Bangers', cursive;
  font-size: 16px;
  box-shadow: 3px 3px 0 black;
  transition: transform 0.1s ease-in-out;
  font-size: "14px";
}

.tabs li.active {
  transform: scale(1.1);
  background-color: black;
}

.tab-content {
  padding: 10px;
}

roi table {
  width: 80%; /* Smaller width */
  margin: 10px auto; /* Center the table */
  border-collapse: collapse;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  background-color: white;
  border: none;
}

roi th, main td {
  padding: 10px; /* Reduced padding */
  text-align: center; /* Makes text bold */
  color: black; /* Sets text color to black */
  text-align: center; /* Centers the text horizontally */
  vertical-align: middle;
  font-size: 14px;
}

roi th {
  background-color: #0b0707;
  color: white;
  font-family: 'Bangers';
}

roi td {
  font-size: 16px;
  font-weight: bold;
}

tabs button.active {
  background-color: #ccc;
}

.image-container {
  position: relative;
  display: inline-block; /* Or 'block' depending on layout */
  overflow: visible; /* Ensure the overflow is visible */
}

.background-image {
  display: block; /* Ensure it's block to remove bottom margin/spacing */
  width: 200px; /* Adjust width as desired */
  height: auto; /* Adjusts height automatically to maintain aspect ratio */
  margin-bottom: 1px;
}

.overlay-image {
  position: absolute;
  top: -7%; /* Move the image up so half is above the container */
  right: -5%; /* Move the image right so half is outside the container */
  width: 50px; /* Adjust the size of the overlay image */
  height: auto; /* Maintain aspect ratio */
}


